<template>
	<div class="d-flex justify-content-start align-items-center flex-column h-100">

		<h3>{{$t('helpMenu.faq')}}</h3>
		<div class="faq-categories">
			<div class="faq-category" v-for="(faqCategory, catIndex) in faqCategories" v-bind:key="catIndex">
				<h5>{{faqCategory.name[$i18n.locale]}}</h5>
				<div class="faqs">
					<div class="faq card" v-for="(faq, index) in faqCategory.faqs" v-bind:key="index" @click.stop="toggleFaq(index)">
						<div class="faq-header">
							<font-awesome-icon icon="chevron-down"  class="mr-2" v-if="activeFaqs.includes(index)" />
							<font-awesome-icon icon="chevron-right"  class="mr-2" v-else />
							{{faq.question[$i18n.locale]}}
						</div>
						<div class="faq-body" v-show="activeFaqs.includes(index)" v-html="faq.answer[$i18n.locale]">
						</div>
					</div>
				</div>
			</div>
		</div>


		<h3 class="mt-4">{{$t('helpMenu.videoGuides')}}</h3>
		<div class="guides">
			<a :href="guide.url[$i18n.locale]" target="_blank" class="guide card" v-for="(guide, index) in videoGuides" v-bind:key="index">
				<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
					<path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/>
				</svg>
				<h6>{{guide.name[$i18n.locale]}}</h6>
				<p>{{guide.description[$i18n.locale]}}</p>
			</a>
		</div>


		<h3 class="mt-4" v-if="pdfGuides.length  > 0">{{$t('helpMenu.pdfGuides')}}</h3>
		<div class="guides pdf-guides mb-5">
			<a :href="guide.url[$i18n.locale]" target="_blank" class="guide pdf-guide card" v-for="(guide, index) in pdfGuides" v-bind:key="index">
				<font-awesome-icon :icon="['fas', 'file-pdf']" />
				<div>
					<h6>{{guide.name[$i18n.locale]}}</h6>
					<p>{{guide.description[$i18n.locale]}}</p>
				</div>
			</a>
		</div>

	</div>
</template>

<script>


export default {
	name: 'HelpView',
	methods: {
		refreshData() {
			this.faqCategories = [
				{
					name: {
						hu: "",
						en: "",
					},
					faqs: [
						{
							question: {
								hu: "Minden ismert forgalom látszódik a térképen?",
								en: "Is all traffic visible on the map?",
							},
							answer: {
								hu: "A t&eacute;rk&eacute;pen a sz&aacute;munkra ismert, 10.000 l&aacute;b alatti forgalom l&aacute;tsz&oacute;dik csak. A rendszer sz&aacute;m&aacute;ra l&aacute;that&oacute; forgalom: <ul> <li>A PilotNet ingyenes applik&aacute;ci&oacute;j&aacute;t haszn&aacute;l&oacute; pil&oacute;t&aacute;k</li> <li>&quot;OGN&quot; ad&oacute;val rendelkező rep&uuml;lőeszk&ouml;z&ouml;k</li> <li>&quot;FLARM&quot; ad&oacute;val rendelkező rep&uuml;lőeszk&ouml;z&ouml;k</li> <li>&quot;ADS-B Out&quot; eszk&ouml;zzel rendelkező rep&uuml;lőeszk&ouml;z&ouml;k</li> <li>SafeSky applik&aacute;ci&oacute;t haszn&aacute;l&oacute; pil&oacute;t&aacute;k</li> </ul> Rep&uuml;l&eacute;s k&ouml;zben viszont a forgalom csak akkor l&aacute;that&oacute; (&eacute;s a saj&aacute;t poz&iacute;ci&oacute; is csak akkor van tov&aacute;bb&iacute;tva) ha az eszk&ouml;znek van internet hozz&aacute;f&eacute;r&eacute;se. Internet el&eacute;r&eacute;s eset&eacute;n az applik&aacute;ci&oacute; fejl&eacute;c&eacute;nek sz&iacute;ne z&ouml;ld, ha nincs kapcsolat, akkor pirosra v&aacute;lt. Ilyenkor nem l&aacute;tsz&oacute;dik forgalom, nem friss&uuml;lnek l&eacute;gterek, de az utols&oacute; szinkroniz&aacute;l&aacute;s időpontj&aacute;nak megfelelő inform&aacute;ci&oacute; el&eacute;rhető ",
								en: "<p>Only known traffic, below 10.000 Ft is visible on the map. The traffic visible to the system includes:</p><ul><li>Pilots using the PilotNet free application.</li><li>Aircraft equipped with &quot;OGN&quot; transponders.</li><li>Aircraft equipped with &quot;FLARM&quot; transponders.</li><li>Aircraft equipped with &quot;ADS-B Out&quot; devices.</li><li>Pilots using the SafeSky application.</li></ul><p>However, during flight, traffic is only visible (and the aircraft&#39;s own position is only transmitted) if the device has internet access. When there is internet access, the header of the application turns green. If there is no connection, it turns red. In the absence of a connection, traffic is not visible, airspace information does not update, but information corresponding to the last synchronization time is available, and all navigation functions are available.</p>",
							}
						},
						{
							question: {
								hu: "Miért kell biztosítani a helyadatokhoz való hozzáférést az eszközön?",
								en: "Why is it necessary to provide access to location data on my device?",
							},
							answer: {
								hu: "Amennyiben az applikáció nem fér hozzá a helyadatokhoz, a működése nem lehetséges, hiszen nem tudja a pozíciót frissíteni a térképen, nem tudja a pozíció adatokat elküldeni a többi felhasználó számára.",
								en: "If the application does not have access to location data, its operation is not possible because it cannot update the position on the map and cannot transmit position data to other users.",
							}
						},
						{
							question: {
								hu: "Hogyan használhatom a telefonom \"transzponderként\"?",
								en: "How can I use my phone as a \"transponder\"?",
							},
							answer: {
								hu: "Amennyiben nem akarunk aktívan navigálni az applikáció alapján, de szeretnénk biztosítani a láthatóságunkat a többi\nlégieszköz számára, akkor lehetőség van repülés módban lezárni a képernyőt a kép alján található lakat ikonra kattintva.\nEz azért előnyösebb, mint a telefont lezárni, mert így biztosítva van a helyadatokhoz és internethez való hozzáférés\naz applikáció számára, így zavartalanul tudja küldeni a pozíció adatokat. Telefon lezárása esetén gyakran elvesznek a helyadatok, vagy megszakad az internet hozzáférés, így mindenképp az applikáción belüli lezárást javasoljuk.\nLezárt képernyővel zsebre tehető a telefon, és szimpla transzponderként viselkedik, valamint a repülés is rögzül. Szintén hasznos a funkció ha eszközünk töltöttsége alacsony, vagy túlmelegedne, ebben az állapotban kevesebbet fogyaszt az eszköz",
								en: "If you do not want to actively navigate using the application but still want to ensure your visibility to other aircraft, you have the option to lock the screen during flight by clicking on the lock icon at the bottom of the screen in flying mode.<br/>This is more advantageous than locking your entire phone because it ensures access to location data and the internet for the application is still available, allowing it to send position data seamlessly. When you lock your phone, location data is often lost or internet access is interrupted, so we recommend using the in-app lock feature.<br/>With the screen locked, you can put your phone in your pocket, and it will function as a simple transponder while recording your flight. This feature is also useful when your device's battery is low or if it's overheating, as it consumes less power in this state.",
							}
						},
						{
							question: {
								hu: "Nagyon gyenge az internet kapcsolat a telefonon repülés közben, hogyan lehet ezt javítani?",
								en: "The internet connection on the phone is very weak during flight, how can this be improved?",
							},
							answer: {
								hu: "A mobilinternet lefedettség nagyban változik a repülési magasság, hely, domborzat függvényében. Szintén fontos az eszköz is, nagy különbségek vannak különböző telefonok között.",
								en: "Mobile internet coverage varies greatly depending on flight altitude, location, terrain, and also depends on the specific device being used. ",
							}
						},
						{
							question: {
								hu: "Tableten is futhat a PilotNet?",
								en: "Can the PilotNet be used on a tablet?",
							},
							answer: {
								hu: "Természetesen bármilyen iOS vagy Android alapú eszközön lehet használni a PilotNet applikációt. A rendszer azonban internet alapú, így internet hozzáférést biztosítani kell a tablet számára. Ehhez ajánljuk külső eszközünket, ami ami megbízható internet kapcsolatot biztosít tesztjeink alapján akár 9000 láb magasságon is, sőt, saját GNSS (GPS) vevő nélküli tabletek (Apple iPad-ek bizonyos verziói) számára külső GPS jelet is képes biztosítani.\nTermészetesen tabletbe helyezett SIM kártya, vagy telefonról megosztott internet is biztosítja a rendszer működését alacsonyabb magasságokon",
								en: "Certainly, you can use the PilotNet application on any iOS or Android-based device. However, since the system relies on the internet, you will need to provide internet access to your tablet. We recommend our external device, which can provide reliable internet access, even at altitudes of up to 9000 feet, based on our tests. Additionally, for tablets without their own GNSS (GPS) receiver (e.g. certain versions of Apple iPads), it can provide external GPS signals.<br/>Of course, you can also use a SIM card inserted directly into the tablet or share internet from your phone to ensure the system works at lower altitudes.",
							}
						},
						{
							question: {
								hu: "Visszanézhetem a repüléseimet?",
								en: "Can I review my flights?",
							},
							answer: {
								hu: "Az applikációval végzett repülések adatai elérhetőek a repüléseim menüből. A bal alsó sarokban lévő gombok segítségével levehető/visszatehető tervezett útvonal a térképre, magasság-távolság diagram megnyitható, valamint a repülést le lehet tölteni .gpx formátumban",
								en: "Yes, the data from flights done with the application can be accessed in the \"My Flights\" menu. You can toggle the planned route on and off on the map using the buttons in the lower left corner. You can also open the altitude-distance diagram and download the flight in .gpx format.",
							}
						},
						{
							question: {
								hu: "Hogy rögzítsem biztonságosan a tabletem/telefonom?",
								en: "How can I securely mount my tablet/phone?",
							},
							answer: {
								hu: "A PilotNet csapat rögzítésre a térdblokkos megoldást javasolja. Tablet és telefontartó térdblokkok, megévő térdblokkra rögzítést garantáló egyedi tartók elérhetőek nálunk, 3D nyomtatás technológiát használva. Egyedi dizájn, azonosító (név, becenév, logo) elhelyezésének lehetőségével - egy hasznos ajándékötlet repülős ismerősők számára :)  Érdeklődés esetén kérlek írj nekünk az <a href=\"mailto:info@pilotnet.hu\">info@pilotnet.hu</a> email címre",
								en: "The PilotNet team recommends a kneeboard-style fixing solution for securing your tablet or phone. We offer kneeboard phone and tablet holders, holders which you can attach to your existing kneeboard, and custom mounts that guarantee secure attachment using 3D printing technology. These mounts can be customized with unique designs and personalization options (such as name, nickname, logo), making them a useful gift idea for yourself, or you pilot friends :) If you're interested, please contact us at <a href=\"mailto:info@pilotnet.hu\">info@pilotnet.hu</a>"
							}
						},
					]
				}
			]
			this.videoGuides = [
				{
					name: {
						hu: "Webes felület használata",
						en: "Using the Web Interface"
					},
					description: {
						hu: '',
						en: ''
					},
					url:{
						"hu": "https://youtube.com/playlist?list=PLZFgn5phPydEloyC7bZM-lZiaBcmdtTZ7&si=u49FO_S7TW_XIoiQ",
						"en": "https://youtube.com/playlist?list=PLZFgn5phPydEloyC7bZM-lZiaBcmdtTZ7&si=u49FO_S7TW_XIoiQ"
					},
				},
				{
					name: {
						hu: "App használata",
						en: "Using the App"
					},
					description: {
						hu: '',
						en: ''
					},
					url:{
						"hu": "https://youtube.com/playlist?list=PLZFgn5phPydE9PU-fCUbUEbTZSA7ToHry&si=aESHwQTBWCahsdz8",
						"en": "https://youtube.com/playlist?list=PLZFgn5phPydE9PU-fCUbUEbTZSA7ToHry&si=aESHwQTBWCahsdz8"
					}
				}
			]
			this.pdfGuides = [
				{
					name: {
						hu: 'PilotNet használati útmutató',
						en: 'PilotNet User Manual'
					},
					description: {
						hu: '',
						en: ''
					},
					url:{
						"hu": "https://pilotnet.hu/manual.pdf",
						"en": "https://pilotnet.hu/manual.pdf"
					},
				},
			]
		},
		/** @param {number} index */
		toggleFaq(index) {
			if (this.activeFaqs.includes(index)) {
				this.activeFaqs = this.activeFaqs.filter(i => i !== index)
			} else {
				this.activeFaqs.push(index)
			}
		}
	},
	data(){
		return {

			/** @type {FaqCategory[]} */
			faqCategories: [],

			/** @type {VideoGuide[]} */
			videoGuides: [],

			pdfGuides: [],

			/** @type {number[]} */
			activeFaqs: [],
		}
	},
	beforeMount() {
		this.refreshData()
	},
	mounted() {
	},
}


</script>


<style scoped>

.btn-primary.btn-main {
	word-break: keep-all;
	text-align: center;
	padding: 0.5rem 1rem;
	border: none;
	border-radius: 0.5rem;
	box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5);
}


.card {
	padding: 1rem;
	border-radius: 0.5rem;
	border: 1px solid #dee2e6;
	cursor: pointer;
	transition: border-color 0.1s ease-in-out;
}

.contact-container {
	max-width: 400px;
	width: 100%;
}

.card.active {
	border-color: var(--primary);
}


.card:hover {
	border-color: var(--primary);
}


/* Outline (bc safari likes to not work) */
.card.active::before {
	content: "";
	position: absolute;
	top: -1px;
	right: -1px;
	bottom: -1px;
	left: -1px;
	border: 1px solid var(--primary);
	border-radius: 0.5rem;
}


.faq-categories {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
}

.faq-category {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}



.faqs{
	display: flex;
	flex-direction: column;
	gap: 1rem;
}


.faq-header {
	font-weight: bold;
}

.faq-body{
	color: #6c757d;
}



h3{
	font-size: 1.5rem;
	font-weight: bold;
	text-align: center;
}

h5{
	font-size: 1.1rem;
	color: #6c757d;
	text-align: center;
	margin: 0;
}

@media screen and (max-width: 768px) {
	h3{
		font-size: 1.25rem;
	}

	h5{
		font-size: 1rem;
	}
}


.guides {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;
	margin-top: 1rem;
}

.guides.pdf-guides {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: center;
	justify-content:center;
}

.guide {
	text-align: center;
	text-decoration: none;
	color: inherit;
}

.guide.pdf-guide {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	gap: 1rem;
	text-align: left;
}

.guide svg {
	width: 3rem;
	height: 3rem;
	margin: 0 auto;
	display: block;
	margin-bottom: 0.5rem;
	fill: #FF0000;
}

.guide.pdf-guide p {
	margin: 0;
	max-width: 300px;
}


.guide h6 {
	font-size: 1.1rem;
	font-weight: bold;
	margin: 0;
}

@media screen and (max-width: 568px) {
	.guide svg {
		width: 2rem;
		height: 2rem;
	}
	.guide h6 {
		font-size: 1rem;
	}
	.guide p {
		font-size: 0.8rem;
	}
}

@media screen and (max-width: 468px) {
	.guides {
		grid-template-columns: repeat(1, 1fr);
	}
}

</style>
